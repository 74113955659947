body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:where(.css-dev-only-do-not-override-ys57aq).ant-card-bordered {
  border: 1px solid #f0f0f0;
  -webkit-box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.05);
}

:where(.css-ys57aq).ant-card-bordered{
  -webkit-box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.05);
}

.ant-upload-list-item-name{
  color: blue;
}

.css-dev-only-do-not-override-ys57aq.ant-menu-light .ant-menu-item-selected a{
  color:  #2980b9  !important;
}

.css-ys57aq.ant-menu-light .ant-menu-item-selected a{
  color:  #2980b9  !important;
}
 
:where(.css-1hvxet2).ant-menu-light .ant-menu-item-selected a, :where(.css-1hvxet2).ant-menu-light > .ant-menu .ant-menu-item-selected a, :where(.css-1hvxet2).ant-menu-light .ant-menu-item-selected a:hover, :where(.css-1hvxet2).ant-menu-light > .ant-menu .ant-menu-item-selected a:hover
{
  color: #2980b9 !important;
}