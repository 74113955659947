
#img{
    width: 80%;
}

#text-olvidar{
    text-align: right;
    margin-top: -20px;
}

#text-olvidar:hover{
    cursor: pointer;
}

#text-cuenta{
    text-align: center;
    font-size: 12px;

}

@media screen and (max-width:768px) {
    #img{
        height: 90%;
        width: 90%;
    }
}