.text.modal{
    margin-right: 20px !important;
}

.mostrar-indicador{
    display: flex;
    flex-direction: row;
    background-color: #f2f3f4;
    justify-content: space-between;
    margin-top: 10px;
}