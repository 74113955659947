/* .budgets {
    display: grid;
    grid-template-columns: repeat(5, 500px);
    gap: 16px 16px;
} */

.budget {
    background-color: white;
    width: 500px;
    height: 360px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.budget h1 {
    font-size: 32px;
}

.budget:hover {
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.div-valor{
    display: flex;
    flex-direction: row;
    width: 40%;
    margin-left: 10%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    justify-content: center;
}

.text{
    /* margin-top: 30px; */
    text-align: center;
}

.img{
    height: 180px;
}

.div-text{
    display:flex;
    flex-direction:column;
    margin-left:-40;
}

.img-card{
    width: 190px;
    height: 200px;
}

@media screen and (max-width:768px) {
    .budget{
        width: 380px;
    }

    .img-card{
        width: 100px;
        height: 150px;
    }

}