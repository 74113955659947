#content-principal{
    display: flex;
    flex-direction: row;
    /* margin-top: %; */
    padding: 32px;
    margin-top: -56px;
}

#content-img{
    width: 50%;
    height: 50%;
    margin-top: 10%;
}

#div-text{
    display: flex;
    flex-direction: column;
    margin: 10%;
    width: 50%;
}

#content-second{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #64B02A;
    width: 100%;
    
}

#title{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 50%;
    justify-content: center;
    margin-bottom: 10%;
}



@media screen and (max-width: 768px) {
    #content-principal{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 2%;
        padding: 0px;
    }

    #content-img{
        display: flex;
        justify-content: center;
        height: 40%;
        width: 100%;
        margin-left: 5%;
    }

    #img-bienvenida{
      max-width: 100%;
    }

    #div-text{
        width: 100%;
    }

    #title{
        width: 100%;
    }
  }
  