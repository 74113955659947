

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.print{
  display: none;
}

@page {
  margin: 0.5cm;
  }

  .report-header{
    border-collapse: collapse;
  }
  .report-header th,.report-header td{
    padding:.5mm;
  }

  .report-header thead th{
    text-align: left;
    font-weight: bold;
    text-align: center;
    
  }
  .report-header td,.report-header th{
    vertical-align:top
  }

  .report-header tbody th{
    background-color: #CCD1D1;
    text-align: center;
  }


@media print{

  hr{
    height: 4px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  img{
    max-width: 100%;
    width:150px;
  }
  table,td,th{
    border-collapse: separate;
    border:2px solid white;
  }
  * { color: black; background: white; }
  table { font-size: 80%; }
  .print{
    /* display: block; */
    visibility: visible !important;
  }
  *{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
  }
}


